import React, { useState, useEffect } from "react";
import MyButton from '../Utils/myButton';
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";



const Discount =() => {

  const [discountStart, setDiscountStart] = useState(0);
  const discountEnd = new Date().getFullYear() - 2008;


  const porcentage = () => {
    if (discountStart < discountEnd) {
      setDiscountStart(discountStart + 1)
    }
  };

  useEffect(() => {
    setTimeout(() => {
      porcentage();
    }, 100);
  }, [discountStart]);

    

    return (
      <div className="center_wrapper">
        <div className="discount_wrapper">
          <Fade onReveal={() => porcentage()}>
            <div className="discount_porcentage">
              <span>{discountStart}</span>
              <span>lat na rynku</span>
            </div>
          </Fade>

          <Slide right>
            <div className="discount_description">
              <h3>Firma została założona w 2008 roku</h3>
              <p>
                W trakcie pracy firmy z powodzeniem wdrożono i przekazano klientom ponad 500 projektów, w tym z udziałem partnerów, którzy działali jako dostawcy konstrukcji, sprzętu, nowych technologii z takich krajów jak Rosja, Polska, Włochy.
              </p>
              <MyButton 
                text="Więcej "
                bck="#ffa800"
                color="#ffffff"
                link="https://www.facebook.com/bugpro.by/"
              />
            </div>
          </Slide>
        </div>
      </div>
    );
}

export default Discount;
